module.exports = [{
      plugin: require('/codebuild/output/src215680463/src/iod-gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-139024251-1","head":true},
    },{
      plugin: require('/codebuild/output/src215680463/src/iod-gatsby/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/codebuild/output/src215680463/src/iod-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
